import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Container from "../components/container";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 | Not found" />
    <Container>
      <h1>404 | NOT FOUND</h1>
      You just hit a route that doesn&#39;t exist... Click <Link to="/">here</Link> to go home!
    </Container>
  </Layout>
);

export default NotFoundPage;
